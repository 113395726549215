import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { useLocation } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu'

import Resume from '../../documents/AyakaDemuraResume.pdf';
import Logo from '../../imgs/nav/logo.svg';

// https://onebitious.net/react_burger/
// class Header extends React.Component {

//     showSetting(event) {
//       event.preventDefault();
//     }
  
//     render () {
//       return (
//         <header className='Header_wrapper'>
//             <div className='Header'>
//                 <a className='Header_logo' href="/">
//                     <img src={Logo} alt="Ayaka Demura" />
//                 </a>

//                 <div id="outer-container">
//                     <Menu pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" } right>
//                         <main id="page-wrap">
//                         <HashLink
//                             className='Header_navigation_item Header_navigation_item__work' 
//                             smooth to="/#Work"
//                             // style={
//                             // isActive("#Work")
//                             //     ? {
//                             //         color: "#3970B8"
//                             //     }
//                             //     : {}
//                             // }
//                             // activeStyle={{
//                             // fontWeight: "bold",
//                             // color: "#3970B8"
//                             // }}
//                         >
//                             Work
//                         </HashLink>
//                         <a className="menu-item" href="/#Work">Work</a>
//                         <a className="menu-item" href="/about">About</a>
//                         <a className="menu-item" href="/contact">Resume</a>
//                         {/* <a onClick={ this.showSettings } className="menu-item--small" href="">Settings</a> */}
//                         </main>
//                     </Menu>
//                 </div>
//             </div>
//         </header>
        
//       );
//     }
// }

const Header = (props) => {
    const { hash } = useLocation();
    const isActive = (iHash) => hash === iHash;

    return (
        <header className='Header_wrapper'>
            <div className='Header'>
                <a className='Header_logo' href="/">
                    <img src={Logo} alt="Ayaka Demura" />
                </a>
                {/* <HashLink 
                    className='Header_logo'
                    smooth to="/"
                >
                    <img src={Logo} alt="Ayaka Demura" />  
                </HashLink> */}

                <ul className='Header_navigation'>
                    <li>
                        <HashLink
                            className='Header_navigation_item Header_navigation_item__work' 
                            smooth to="/#Work"
                            style={
                            isActive("#Work")
                                ? {
                                    color: "#42518D"
                                }
                                : {}
                            }
                            activeStyle={{
                            fontWeight: "bold",
                            color: "#42518D"
                            }}
                        >
                            Work
                        </HashLink>
                    </li>
                    <li>
                        <HashLink
                            className='Header_navigation_item Header_navigation_item__about' 
                            smooth to="/About"
                            style={
                                isActive("#About")
                                    ? {
                                        color: "#42518D"
                                    }
                                    : {}
                                }
                                activeStyle={{
                                fontWeight: "bold",
                                color: "#42518D"
                            }}
                        >
                            About
                        </HashLink>
                    </li>
                    <li>
                        {/* <HashLink
                            className='Header_navigation_item Header_navigation_item_contact' 
                            smooth to="/#Contact"
                            style={
                                isActive("#Contact")
                                    ? {
                                        color: "#3970B8"
                                    }
                                    : {}
                                }
                                activeStyle={{
                                fontWeight: "bold",
                                color: "#3970B8"
                            }}
                        >
                            Contact
                        </HashLink> */}
                        <a className='Header_navigation_item Header_navigation_item_resume' href={Resume} target="_blank" rel="noreferrer">
                            Resume
                        </a>
                    </li>
                </ul>
            </div>
            
        </header>  
    );
}

export default Header;