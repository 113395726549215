import React from 'react';
import RModalImages from "react-modal-image";

import DailyUI01 from "../../imgs/dailyUI/dailyUI_01.jpg";
import DailyUI02 from "../../imgs/dailyUI/dailyUI_02.jpg";
import DailyUI03 from "../../imgs/dailyUI/dailyUI_03.jpg";
import DailyUI04 from "../../imgs/dailyUI/dailyUI_04.jpg";
import DailyUI05 from "../../imgs/dailyUI/dailyUI_05.jpg";
import DailyUI06 from "../../imgs/dailyUI/dailyUI_06.jpg";
import DailyUI07 from "../../imgs/dailyUI/dailyUI_07.jpg";
import DailyUI08 from "../../imgs/dailyUI/dailyUI_08.jpg";


function DailyUIContent() {
    return (
        <div className='DailyUI_wrapper'>
            <div className='DailyUI_hero'>
                <h2 className='DailyUI_hero_text'>Daily UI Challenge</h2>
            </div>

            <div className='DailyUI_intro'>
                <p className='DailyUI_intro_text'>I am challenging myself to design a unique screen every day <br className='pc_only'></br>as a way to enhance my creativity and develop my visual design skills.</p>
            </div>

            <div className='DailyUI_list_wrapper'>
                <div className='DailyUI_list'>
                    <div className='DailyUI_list_item'>
                        <RModalImages
                            className='DailyUI_list_item_img'
                            small={DailyUI01}
                            large={DailyUI01}
                            alt="Sign Up"
                        />
                        {/* <img className='DailyUI_list_item_img' src={DailyUI01} alt="Sign up form" /> */}
                        <p className='DailyUI_list_item_text'>
                            Sign Up
                        </p>
                    </div>

                    <div className='DailyUI_list_item'>
                        <RModalImages
                            className='DailyUI_list_item_img'
                            small={DailyUI02}
                            large={DailyUI02}
                            alt="Credit Card Checkout"
                        />
                        {/* <img className='DailyUI_list_item_img' src={DailyUI02} alt="Checkout" /> */}
                        <p className='DailyUI_list_item_text'>
                            Credit Card Checkout
                        </p>
                    </div>

                    <div className='DailyUI_list_item'>
                        <RModalImages
                            className='DailyUI_list_item_img'
                            small={DailyUI03}
                            large={DailyUI03}
                            alt="Landing Page"
                        />
                        {/* <img className='DailyUI_list_item_img' src={DailyUI02} alt="Checkout" /> */}
                        <p className='DailyUI_list_item_text'>
                            Landing Page
                        </p>
                    </div>

                    <div className='DailyUI_list_item'>
                        <RModalImages
                            className='DailyUI_list_item_img'
                            small={DailyUI04}
                            large={DailyUI04}
                            alt="Settings"
                        />
                        {/* <img className='DailyUI_list_item_img' src={DailyUI02} alt="Checkout" /> */}
                        <p className='DailyUI_list_item_text'>
                            Settings
                        </p>
                    </div>

                    <div className='DailyUI_list_item'>
                        <RModalImages
                            className='DailyUI_list_item_img'
                            small={DailyUI05}
                            large={DailyUI05}
                            alt="404 Page"
                        />
                        {/* <img className='DailyUI_list_item_img' src={DailyUI02} alt="Checkout" /> */}
                        <p className='DailyUI_list_item_text'>
                            404 Page
                        </p>
                    </div>

                    <div className='DailyUI_list_item'>
                        <RModalImages
                            className='DailyUI_list_item_img'
                            small={DailyUI06}
                            large={DailyUI06}
                            alt="Flash Message"
                        />
                        {/* <img className='DailyUI_list_item_img' src={DailyUI02} alt="Checkout" /> */}
                        <p className='DailyUI_list_item_text'>
                            Flash Message
                        </p>
                    </div>

                    <div className='DailyUI_list_item'>
                        <RModalImages
                            className='DailyUI_list_item_img'
                            small={DailyUI07}
                            large={DailyUI07}
                            alt="Music Player"
                        />
                        {/* <img className='DailyUI_list_item_img' src={DailyUI02} alt="Checkout" /> */}
                        <p className='DailyUI_list_item_text'>
                            Music Player
                        </p>
                    </div>

                    <div className='DailyUI_list_item'>
                        <RModalImages
                            className='DailyUI_list_item_img'
                            small={DailyUI08}
                            large={DailyUI08}
                            alt="Social Share"
                        />
                        {/* <img className='DailyUI_list_item_img' src={DailyUI02} alt="Checkout" /> */}
                        <p className='DailyUI_list_item_text'>
                            Social Share
                        </p>
                    </div>
                </div>

                {/* <p className='DailyUI_text_bottom'>
                    More design coming soon
                </p> */}
            </div>
        </div>    
    );
}

export default DailyUIContent;