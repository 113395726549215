import AlixKerryDetail from "./details";
import AlixKerryWireframe from "./wireframe";
import AlixKerryOutcome from "./outcome";
import AlixKerryTakeaways from "./takeaways";

import ChatAidHero from "../../../imgs/caseStudy/ChatAid/chataid_hero.jpg"

function AlixKerry() {
    return (
        <div className='CaseStudy_wrapper'>
            <div className='CaseStudy_hero'>
                <img className='CaseStudy_outcome_design' src={ChatAidHero} alt="Chat Aid"/>
            </div>

            <div className='CaseStudy_container'>
                <AlixKerryDetail />
                <AlixKerryWireframe />
                <AlixKerryOutcome />
                <AlixKerryTakeaways />
            </div>
        </div>
    );
}

export default AlixKerry;