import React from 'react';

import VeganMenuAbout from "./about";
import VeganMenuFinalDesign from './finalDesign';

import VeganMenuHero from "../../../imgs/caseStudy/VeganMenu/VeganMenu_hero.jpg"

function VeganMenu() {
    return (
        <div className='CaseStudy_wrapper'>
            <div className='CaseStudy_hero CaseStudy_hero__VeganMenu'>
                <img className='CaseStudy_hero_img' src={VeganMenuHero} alt="Vegetarian & Vegan Menu"/>
            </div>

            <div className='CaseStudy_container'>
                <VeganMenuAbout />
                <VeganMenuFinalDesign />
            </div>
        </div>
    );
}
    
export default VeganMenu;