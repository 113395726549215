function VeganMenuAbout() {
    return (
        <div className='CaseStudy_about_wrapper CaseStudy_section CaseStudy_section__about'>
            <div className='CaseStudy_about_row'>
                <div className='CaseStudy_about_title_wrapper'>
                    <h2 className='CaseStudy_about_title_title'>
                        Vegetarian & Vegan Menu
                    </h2>
                    <span className='CaseStudy_about_title_text'>
                        Restaurant
                    </span>
                </div>

                <p className='CaseStudy_about_text'>
                    A restaurant client needed vegetarian & vegan menu page for their customers. The page is designed to showcase the restaurant's plant-based options, highlighting the diversity and richness of their menu.
                </p>
            </div>

            <div className='CaseStudy_about_type'>
                <div className='CaseStudy_about_type_item'>
                    <h3 className='CaseStudy_about_type_item_title'>
                        Project Type<span className='sp_only'>:</span>
                    </h3>
                    <p className='CaseStudy_about_type_item_text'>
                        Client Work
                    </p>
                </div>

                <div className='CaseStudy_about_type_item'>
                    <h3 className='CaseStudy_about_type_item_title'>
                        Role<span className='sp_only'>:</span>
                    </h3>
                    <p className='CaseStudy_about_type_item_text'>
                        Web Design & Developement, <br className='pc_only'></br>Graphic Design
                    </p>
                </div>

                <div className='CaseStudy_about_type_item'>
                    <h3 className='CaseStudy_about_type_item_title'>
                        Timeline<span className='sp_only'>:</span>
                    </h3>
                    <p className='CaseStudy_about_type_item_text'>
                        4 weeks, 2022
                    </p>
                </div>
            </div>
        </div>
    );
}

export default VeganMenuAbout;