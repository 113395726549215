import React from 'react';
import ProgressBar from "react-progressbar-on-scroll";

import Header from "../../Components/header/header";
import MonaCo from "../../Components/caseStudy/MonaCo/MocaCo";
import Contact from "../../Components/contact/contact";
import Footer from "../../Components/footer/footer";

const CaseStudyMonaCo = () => {
  return (
    <div className="CaseStudy">
         <ProgressBar
          color="#CA9C7C"
        //   gradient={true}
        //   colorGradient="#F8F1EB"
          height={6}
        />
        <Header />
        <MonaCo />
        <Contact />
        <Footer />
    </div>
  );
}

export default CaseStudyMonaCo;