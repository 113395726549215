function AlixKerryWireframe () {
    return (
        <div className='CaseStudy_wireframe CaseStudy_section'>
            <h3 className='CaseStudy_item_title CaseStudy_item_title__alixkerry'>
                Wireframe
            </h3>

        </div>
    );
}

export default AlixKerryWireframe;