import VeganMenuFinalDesignImage from "../../../imgs/caseStudy/VeganMenu/VeganMenu_desktop.jpg"

function VeganMenuFinalDesign() {
    return (
        <div className='CaseStudy_finalDesign_wrapper CaseStudy_finalDesign_wrapper__veganManu CaseStudy_section__maxWidth'>
            <div className='CaseStudy_section_content_item'>
                <img className='CaseStudy_section_content_item_img CaseStudy_section_content_item_img__finalDesign__veganMenu' src={VeganMenuFinalDesignImage} alt="Vegetarian & Vegan Menu Design"/>
            </div>
        </div>
    );
}

export default VeganMenuFinalDesign;