function MonaCoAbout() {
    return (
        <div className='CaseStudy_about_wrapper CaseStudy_section CaseStudy_section__about'>
            <div className='CaseStudy_about_row'>
                <div className='CaseStudy_about_title_wrapper'>
                    <h2 className='CaseStudy_about_title_title'>
                        Mona & Co
                    </h2>
                    <span className='CaseStudy_about_title_text'>
                        Fashion E-commerce
                    </span>
                </div>

                <p className='CaseStudy_about_text'>
                    Mona & Co is a <span className="bold">handmade apparel brand </span>based in Ventura, California. They are dedicated to creating unique, upcycled products, all of which are locally handmade. Their brand features many unique designs sourced from all over the world.
                </p>
            </div>

            <div className='CaseStudy_about_type'>
                <div className='CaseStudy_about_type_item'>
                    <h3 className='CaseStudy_about_type_item_title'>
                        Project Type<span className='sp_only'>:</span>
                    </h3>
                    <p className='CaseStudy_about_type_item_text'>
                        Client Work
                    </p>
                </div>

                <div className='CaseStudy_about_type_item'>
                    <h3 className='CaseStudy_about_type_item_title'>
                        Role<span className='sp_only'>:</span>
                    </h3>
                    <p className='CaseStudy_about_type_item_text'>
                        Web Design, <br className='pc_only'></br>Shopify Development
                    </p>
                </div>

                <div className='CaseStudy_about_type_item'>
                    <h3 className='CaseStudy_about_type_item_title'>
                        Timeline<span className='sp_only'>:</span>
                    </h3>
                    <p className='CaseStudy_about_type_item_text'>
                        6 weeks, 2021
                    </p>
                </div>
            </div>
        </div>
    );
}

export default MonaCoAbout;