import React, { Component }  from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./Pages/Home/home";
import Work from "./Components/work/work";
import About from "./Pages/About/about";
import Contact from "./Components/contact/contact";
import CaseStudyChatAid from './Pages/CaseStudy/chataid';
import CaseStudyAlixKerry from './Pages/CaseStudy/alixkerry';
import CaseStudyMonaCo from './Pages/CaseStudy/MonaCo';
import CaseStudyVeganMenu from './Pages/CaseStudy/VeganMenu';
import DailyUI from './Components/dailyUI/dailyUI';
import Page404 from './Pages/404/404';
import ScrollToTop from './ScrollToTop';
import BackToTop from './Components/backButton/BackToTop';

class App extends Component {
  render () {
    return (
      <div className="App">
          <BackToTop />
          <Router >
            <ScrollToTop>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/#Work" element={<Work />} />
                <Route exact path="/About" element={<About />} />
                <Route exact path="/#Contact" element={<Contact />} />
                <Route exact path="/Chataid" element={<CaseStudyChatAid />} />
                <Route exact path="/AlixKerry" element={<CaseStudyAlixKerry />} />
                <Route exact path="/MonaCo" element={<CaseStudyMonaCo />} />
                <Route exact path="/VeganMenu" element={<CaseStudyVeganMenu />} />
                <Route exact path="/DailyUI" element={<DailyUI />} />
                <Route exact path="*" element={<Page404 />}/>
              </Routes>
            </ScrollToTop>
          </Router>
      </div>
    );
  } 
}

export default App;
