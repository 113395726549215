import Persona1 from "../../../imgs/caseStudy/ChatAid/chataid_persona_1.jpg"
import Persona2 from "../../../imgs/caseStudy/ChatAid/chataid_persona_2.jpg"

function ChatAidPersonas() {
    return (
        <div className='CaseStudy_personas CaseStudy_section'>
            <h3 className='CaseStudy_item_title CaseStudy_item_title__chataid'>
                Personas
            </h3>
            <p className='CaseStudy_personas_text'>
                From these research, I created personas that represent users of the application in order to understand the user’s needs, experiences and goals when using it. 
            </p>

            <div className='CaseStudy_personas_content'>
                <div className='CaseStudy_personas_content_item'>
                    <p className='CaseStudy_personas_content_item_text'>
                        Persona 1: teen who has difficulty sharing their feelings
                    </p>
                    <img className='CaseStudy_personas_content_item_img CaseStudy_personas_content_item_img--persona1' src={Persona1} alt="Persona 1"/>
                </div>

                <div className='CaseStudy_personas_content_item'>
                    <p className='CaseStudy_personas_content_item_text'>
                        Persona 2: teen who wants to learn about mental health to support her family member struggling with mental health
                    </p>
                    <img className='CaseStudy_personas_content_item_img' src={Persona2} alt="Persona 2"/>
                </div>
            </div>
        </div>
    );
}

export default ChatAidPersonas;