import React from 'react';

import ChatAidDetail from "./detail";
import ChatAidProblem from "./problem";
import ChatAidGoal from "./goal";
import ChatAidReserach from "./research";
import ChatAidPersonas from "./personas";
import ChatAidSitemapUserflow from "./sitemap_userflow";
import ChatAidUserTestJourney from "./usertest_journey";
import ChatAidOutcome from "./outcome";
import ChatAidTakeaways from "./takeaways";

import ChatAidHero from "../../../imgs/caseStudy/ChatAid/chataid_hero.jpg"

function ChatAid() {
    return (
        <div className='CaseStudy_wrapper'>
            <div className='CaseStudy_hero'>
                <img className='CaseStudy_outcome_design' src={ChatAidHero} alt="Chat Aid"/>
            </div>

            <div className='CaseStudy_container'>
                <ChatAidDetail />
                <ChatAidProblem />
                <ChatAidGoal />
                <ChatAidReserach />
                <ChatAidPersonas />
                <ChatAidSitemapUserflow />
                <ChatAidUserTestJourney />
                <ChatAidOutcome />
                <ChatAidTakeaways />
            </div>
        </div>
    );
}
    
export default ChatAid;