function MonaCoContext() {
    return (
        <div className='CaseStudy_context_wrapper CaseStudy_section'>
            <div className='CaseStudy_section_title'>
                <h3 className='CaseStudy_section_title_title'>
                    Context
                </h3>
            </div>

            <div className='CaseStudy_section_content'>
                <div className='CaseStudy_section_content_item'>
                    <h4 className='CaseStudy_section_content_item_title'>
                        Overview
                    </h4>
                    <p className='CaseStudy_section_content_item_text'>
                        They currently have a website created using a Shopify template. <span className='bold'>They would like a visually appealing website that accurately represents their brand</span>. Additionally, they hope to increase website traffic, brand awareness, and newsletter subscriptions.
                    </p>
                </div>

                <div className='CaseStudy_section_content_item'>
                    <h4 className='CaseStudy_section_content_item_title'>
                        Target Audience
                    </h4>
                    <p className='CaseStudy_section_content_item_text'>
                        At present, they primarily create bags and accessories for females aged teens to 40. However, they have plans to expand their product line to include T-shirts for both males and females aged teens to 30 in the near future. Therefore, <span className='bold'>the website should have an appealing design that caters to a wide age range and all genders.</span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default MonaCoContext;