import React ,{ Component } from 'react';

import Header from "../../Components/header/header";
import AboutContent from "../../Components/about/about";
import Contact from "../../Components/contact/contact";
import Footer from "../../Components/footer/footer";

class About extends Component {
    render() {
        return (
            <div className='About'>
                <Header />
                <AboutContent />
                <Contact />
                <Footer />
            </div>
        );
    }
}

export default About;