import InterviewQuestions from "../../../imgs/caseStudy/ChatAid/chataid_interview_questions.jpg"

function ChatAidInterview() {
    return (
        <div className='CaseStudy_reserach_interview'>
            <h4 className='CaseStudy_reserach_interview_title'>
                Interview <span className='light'>(Qualitative research)</span>
            </h4>
            <p className='CaseStudy_reserach_survey_text'>
                I interviewed Caitlyn, my target audience (teen, high school student). I was able to learn about her personal mental health experience/journey. The questions I asked were:
            </p>
            <img className='CaseStudy_reserach_interview_question' src={InterviewQuestions} alt="Interview Questions"/>
        
            <div className='CaseStudy_reserach_interview_result'>
                <h5 class='CaseStudy_reserach_interview_result_title'>
                    What I learned & found:
                </h5>
                <ol className='CaseStudy_reserach_interview_result_list'>
                    <li className='CaseStudy_reserach_interview_result_list_item'>
                        1. She likes to journal to chest off and figure out her feelings. She recommends journaling.
                    </li>
                    <li className='CaseStudy_reserach_interview_result_list_item'>
                        2. She thinks journaling is helpful because you do not always have someone to talk to.
                    </li>
                    <li className='CaseStudy_reserach_interview_result_list_item'>
                        3. She feels sharing her thoughts and feelings on social media is easier than face to face.
                    </li>
                </ol>
            </div>

            <a className='CaseStudy_reserach_interview_link' href="https://drive.google.com/file/d/1Xb_S6KqA907RImxgxYJl6iILMs4hFx2D/view?usp=sharing" target="_blank" rel="noreferrer">
                Interview questions & results
            </a>
        </div>
    );
}

export default ChatAidInterview;