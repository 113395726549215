import React from 'react';

import Header from "../../Components/header/header";

function Page404() {
  return (
    <div className='Page404'>
        <Header />
        <h2 className='Page404_title'>
            404
        </h2>
        <p className='Page404_text'>
            Sorry page not found
        </p>
        <a className='Page404_link' href="/">
            Go Back Home
        </a>
    </div>
  );
}

export default Page404;