function AlixKerryTakeaways() {
    return (
        <div className='CaseStudy_takeaways CaseStudy_section'>
            <h3 className='CaseStudy_item_title CaseStudy_item_title__alixkerry'>
                Takeaways
            </h3>
            <div className='CaseStudy_takeaways_content_wrapper'>
                <div className='CaseStudy_takeaways_content'>
                    <h4 className='CaseStudy_takeaways_content_title'>
                        Research to design
                    </h4>
                    <p className='CaseStudy_takeaways_content_text'>
                        This is my first UI/UX project. I found that research and data were great recourse to design. It was really helpful to know user experience through user testing. I used to rely on my personal assumption or opinions from small groups. Obviously, it was a huge mistake...
                    </p>
                    <p className='CaseStudy_takeaways_content_text'>
                        Each research process helped me to empathy users' experiences and pain points. Analyzing data is helpful to create a great user interface and experience.
                    </p>
                </div>
                <div className='CaseStudy_takeaways_content'>
                    <h4 className='CaseStudy_takeaways_content_title'>
                        Importance of communication
                    </h4>
                    <p className='CaseStudy_takeaways_content_text'>
                        I re-learned the importance of communication. I talked with many people through the project and I really enjoyed it. It was such a great opportunity to discover different thoughts and opinions. I learned a lot of things from them and they inspired me greatly.
                    </p>
                    <p className='CaseStudy_takeaways_content_text'>
                        I want users to enjoy communicating with the app as I did. I hope that they find the importance of communication and how good communication can make their life better :)
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AlixKerryTakeaways;