import React from 'react';
// import { HashLink } from 'react-router-hash-link';

// import Down from '../../imgs/ico/ico_arrow_down.svg';

function Hero() {
    return (
        <section className='Hero'>
            <div className='Hero_wrapper'>
                <div className='Hero_title_wrapper'>
                    <h2 className='Hero_title'>
                        <span className='Hero_title_name'>Hi, I’m <span className='Hero_title_name_bold'>Ayaka</span></span><br></br>
                        <span className='Hero_title_job'><span className='Hero_title_job_bold'>Visual</span> & <span className='Hero_title_job_bold'>UX Designer</span></span>
                    </h2>
                    <span className='Hero_text'>Made in Japan 🇯🇵 & Sushi 🍣</span>
                    <span className='Hero_text'>With a passion for problem-solving through design</span>
                </div>
                {/* <HashLink 
                        smooth to="/#Work"
                > */}
                <div className='Hero_scroll'>
                    <span class="Hero_scroll_down"></span>
                    <span className='Hero_scroll_text'>Scroll Down</span>
                </div>   
                
                <div class="scroll-down4"></div>

                {/* </HashLink> */}
            </div>
        </section>
    );
}
  
export default Hero;