import React from 'react';

// import WorkChatAid from '../../imgs/work/work_chataid.jpg';
// import WorkPPlane from '../../imgs/work/work_pplane.jpg';
// import Behance from '../../imgs/work/behance_1.jpg';
import DailyUI from '../../imgs/work/work_dailyUI.jpg';
import MonaCo from '../../imgs/work/work_MonaCo.jpg';
// import VeganMenu from '../../imgs/work/work_vegan.jpg';
import { HashLink } from 'react-router-hash-link';

import { Icon } from '@iconify/react';

const Work = (props)  => {

    return (
        <section className='Work' id='Work'>
            <h3 className='Work_title'>My Work</h3>
            
            <div className='Work_caseStudy_list'>
                {/* <HashLink className='Work_caseStudy Work_caseStudy__chataid' smooth to ="/Chataid">
                    <div className='Work_caseStudy_wrapper Work_caseStudy_wrapper__chataid'>
                        <div className='Work_caseStudy_row Work_caseStudy_thumbnail'>
                            <img className='Work_caseStudy_thumbnail_img' src={WorkChatAid} alt="Chat Aid" />
                            <div className='Work_caseStudy_thumbnail_mask'>
                                <Icon className='Work_caseStudy_link_icon__mask' icon="dashicons:arrow-right-alt" color="#3970B8" width="30"/>
                            </div>
                        </div>

                        <div className='Work_caseStudy_row'>
                            <div className='Work_caseStudy_title_wrapper'>
                                <span className='Work_caseStudy_title_sub'>
                                    Chat Aid
                                </span>
                                <h4 className='Work_caseStudy_title'>
                                    Mental Health App for <br className='pc_only'></br>Teens and School
                                </h4>
                            </div>
                            
                            <div className='Work_caseStudy_type'>
                                <span className='Work_caseStudy_type_item Work_caseStudy_type_item__chataid'>
                                    UI/UX Design
                                </span>
                                <span className='Work_caseStudy_type_item Work_caseStudy_type_item__chataid'>
                                    App
                                </span>
                            </div>
                            <p className='Work_caseStudy_text'>
                                Created social media app for teens and schools to improve their mental health
                            </p>
                            <a className='Work_caseStudy_link Work_caseStudy_link__chataid' href="/Chataid">
                                <span className='Work_caseStudy_link_text Work_caseStudy_link_text__chataid'>View Work</span>
                                <Icon className='Work_caseStudy_link_icon Work_caseStudy_link_icon__chataid' icon="dashicons:arrow-right-alt" color="#C8A3B5" width="20"/>
                            </a>
                        </div>
                    </div>
                </HashLink> */}

                <HashLink className='Work_caseStudy Work_caseStudy__monaCo' smooth to ="/MonaCo">
                    <div className='Work_caseStudy_wrapper Work_caseStudy_wrapper__monaCo'>
                        <div className='Work_caseStudy_row Work_caseStudy_thumbnail'>
                            <img className='Work_caseStudy_thumbnail_img' src={MonaCo} alt="Mona & Co" />
                            <div className='Work_caseStudy_thumbnail_mask'>
                                <Icon className='Work_caseStudy_link_icon__mask' icon="dashicons:arrow-right-alt" color="#3970B8" width="30"/>
                            </div>
                        </div>

                        <div className='Work_caseStudy_row'>
                            <div className='Work_caseStudy_title_wrapper'>
                                <span className='Work_caseStudy_title_sub'>
                                    Mona & Co
                                </span>
                                <h4 className='Work_caseStudy_title'>
                                    Fashion E-commerce design & development with Shopify
                                </h4>
                            </div>
                            
                            <div className='Work_caseStudy_type'>
                                <span className='Work_caseStudy_type_item Work_caseStudy_type_item__monaCo'>
                                    Web Design & Development
                                </span>
                                <span className='Work_caseStudy_type_item Work_caseStudy_type_item__monaCo'>
                                    Shopify
                                </span>
                            </div>
                            <p className='Work_caseStudy_text'>
                                Web design & development with Shopify for local handmade apparel brand
                            </p>
                            <a className='Work_caseStudy_link Work_caseStudy_link__monaCo' href="/MonaCo">
                                <span className='Work_caseStudy_link_text Work_caseStudy_link_text__monaCo'>View Work</span>
                                <Icon className='Work_caseStudy_link_icon Work_caseStudy_link_icon__monaCo' icon="dashicons:arrow-right-alt" color="#e08b29" width="20"/>
                            </a>
                        </div>
                    </div>
                </HashLink>

                {/* <HashLink className='Work_caseStudy Work_caseStudy__veganMenu' smooth to ="/VeganMenu">
                    <div className='Work_caseStudy_wrapper Work_caseStudy_wrapper__even Work_caseStudy_wrapper__veganMenu'>
                        <div className='Work_caseStudy_row Work_caseStudy_thumbnail'>
                            <img className='Work_caseStudy_thumbnail_img' src={VeganMenu} alt="Vegetarian & Vegan Menu" />
                            <div className='Work_caseStudy_thumbnail_mask'>
                                <Icon className='Work_caseStudy_link_icon__mask' icon="dashicons:arrow-right-alt" color="#3970B8" width="30"/>
                            </div>
                        </div>

                        <div className='Work_caseStudy_row'>
                            <div className='Work_caseStudy_title_wrapper'>
                                <span className='Work_caseStudy_title_sub'>
                                    Restaurant
                                </span>
                                <h4 className='Work_caseStudy_title'>
                                    Vegetarian & vegan menu page design
                                </h4>
                            </div>
                            
                            <div className='Work_caseStudy_type'>
                                <span className='Work_caseStudy_type_item Work_caseStudy_type_item__veganMenu'>
                                    Web Design & Development
                                </span>
                                <span className='Work_caseStudy_type_item Work_caseStudy_type_item__veganMenu'>
                                    Graphic Design
                                </span>
                            </div>
                            <p className='Work_caseStudy_text'>
                                Responsive web page design & development for vegan & vegetarian menu
                            </p>
                            <a className='Work_caseStudy_link Work_caseStudy_link__VeganMenu' href="/VeganMenu">
                                <span className='Work_caseStudy_link_text Work_caseStudy_link_text__veganMenu'>View Work</span>
                                <Icon className='Work_caseStudy_link_icon Work_caseStudy_link_icon__veganMenu' icon="dashicons:arrow-right-alt" color="91A88E" width="20"/>
                            </a>
                        </div>
                    </div>
                </HashLink> */}

                <HashLink className='Work_caseStudy Work_caseStudy__dailyUI' smooth to ="/DailyUI">
                    <div className='Work_caseStudy_wrapper Work_caseStudy_wrapper Work_caseStudy_wrapper__dailyUI'>
                        <div className='Work_caseStudy_row Work_caseStudy_thumbnail'>
                            <img className='Work_caseStudy_thumbnail_img' src={DailyUI} alt="Daily UI Challenge" />
                            <div className='Work_caseStudy_thumbnail_mask'>
                                <Icon className='Work_caseStudy_link_icon__mask' icon="dashicons:arrow-right-alt" color="#3970B8" width="30"/>
                            </div>
                        </div>

                        <div className='Work_caseStudy_row'>
                            <div className='Work_caseStudy_title_wrapper'>
                                {/* <span className='Work_caseStudy_title_sub'>
                                    Daily UI Challenge
                                </span> */}
                                <h4 className='Work_caseStudy_title'>
                                    Daily UI Challenge
                                </h4>
                            </div>
                            
                            <div className='Work_caseStudy_type'>
                                <span className='Work_caseStudy_type_item Work_caseStudy_type_item__dailyUI'>
                                    UI Design
                                </span>
                                <span className='Work_caseStudy_type_item Work_caseStudy_type_item__dailyUI'>
                                    Visual Design
                                </span>
                            </div>
                            <p className='Work_caseStudy_text'>
                                I took on the Daily UI Challenge to enhance my creativity and develop my visual design skills
                            </p>
                            <a className='Work_caseStudy_link Work_caseStudy_link__dailyUI' href="/DailyUI">
                                <span className='Work_caseStudy_link_text Work_caseStudy_link_text__dailyUI'>View Work</span>
                                <Icon className='Work_caseStudy_link_icon Work_caseStudy_link_icon__dailyUI' icon="dashicons:arrow-right-alt" color="3970B8" width="20"/>
                            </a>
                        </div>
                    </div>
                </HashLink>

                {/* <HashLink className='Work_caseStudy Work_caseStudy__chataid' smooth to ="/pPlane">
                <div className='Work_caseStudy_wrapper'>
                        <div className='Work_caseStudy_row Work_caseStudy_thumbnail__pPlane'>
                            <img className='Work_caseStudy_thumbnail_img' src={WorkPPlane} alt="pPlane" />
                        </div>

                        <div className='Work_caseStudy_row Work_caseStudy_row__even'>
                            <div className='Work_caseStudy_title_wrapper'>
                                <span className='Work_caseStudy_title_sub'>
                                    P Plane
                                </span>
                                <h4 className='Work_caseStudy_title'>
                                    Improve Website Design & Usability
                                </h4>
                            </div>
                            
                            <div className='Work_caseStudy_type'>
                                <span className='Work_caseStudy_type_item Work_caseStudy_type_item__pPlane'>
                                    UI/UX Design
                                </span>
                                <span className='Work_caseStudy_type_item Work_caseStudy_type_item__pPlane'>
                                    User Research
                                </span>
                            </div>
                            <p className='Work_caseStudy_text'>
                                Designed a website based on user interviews and research to enhance the usability of a service
                            </p>
                            <a className='Work_caseStudy_link Work_caseStudy_link__pPlane' href="/pPlane">
                                <span className='Work_caseStudy_link_text Work_caseStudy_link_text__pPlane'>View Work</span>
                                <Icon className='Work_caseStudy_link_icon Work_caseStudy_link_icon__pPlane' icon="dashicons:arrow-right-alt" color="#C8A3B5" width="20"/>
                            </a>
                            <span className='Work_caseStudy_link_text Work_caseStudy_link_text__pPlane coming_soon'>Coming soon</span>
                        </div>
                    </div>
                </HashLink> */}

                {/* <div className='Work_caseStudy_list_row'>
                    <HashLink className='Work_caseStudy__small Work_caseStudy__dailyUI' smooth to ="/DailyUI">
                        <div className='Work_caseStudy_wrapper Work_caseStudy_wrapper__small Work_caseStudy_wrapper__dailyUI'>
                        <div className='Work_caseStudy_row Work_caseStudy_thumbnail'>
                            <img className='Work_caseStudy_thumbnail_img' src={DailyUI} alt="Daily UI Challenge" />
                            <div className='Work_caseStudy_thumbnail_mask'>
                                <Icon className='Work_caseStudy_link_icon__mask' icon="dashicons:arrow-right-alt" color="#3970B8" width="30"/>
                            </div>
                        </div>

                            <div className='Work_caseStudy_row__small'>
                                <div className='Work_caseStudy_title_wrapper'>
                                    <h4 className='Work_caseStudy_title'>
                                        Daily UI Challenge
                                    </h4>
                                </div>
                                
                                <div className='Work_caseStudy_type'>
                                    <span className='Work_caseStudy_type_item Work_caseStudy_type_item__dailyUI'>
                                        UI Design
                                    </span>
                                </div>
                                <p className='Work_caseStudy_text'>
                                    I took on the Daily UI Challenge to improve my design skills and creativity
                                </p>
                                <a className='Work_caseStudy_link Work_caseStudy_link__dailyUI' href="/DailyUI">
                                    <span className='Work_caseStudy_link_text Work_caseStudy_link_text__dailyUI'>View Work</span>
                                    <Icon className='Work_caseStudy_link_icon Work_caseStudy_link_icon__dailyUI' icon="dashicons:arrow-right-alt" color="#f79a2f" width="20"/>
                                </a>
                            </div>
                        </div>
                    </HashLink>

                    <a className='Work_caseStudy__small Work_caseStudy__behance' href="https://www.behance.net/ayakademura" target="_blank" rel="noreferrer">
                        <div className='Work_caseStudy_wrapper Work_caseStudy_wrapper__small Work_caseStudy_wrapper__behance'>
                            <div className='Work_caseStudy_row Work_caseStudy_thumbnail'>
                                <img className='Work_caseStudy_thumbnail_img' src={Behance} alt="Behance" />
                                <div className='Work_caseStudy_thumbnail_mask'>
                                    <Icon className='Work_caseStudy_link_icon__mask' icon="dashicons:arrow-right-alt" color="#3970B8" width="30"/>
                                </div>
                            </div>

                            <div className='Work_caseStudy_row__small'>
                                <div className='Work_caseStudy_title_wrapper'>
                                    <h4 className='Work_caseStudy_title'>
                                        Behance
                                    </h4>
                                </div>
                                
                                <div className='Work_caseStudy_type'>
                                    <span className='Work_caseStudy_type_item Work_caseStudy_type_item__behance'>
                                        UI Design
                                    </span>
                                    <span className='Work_caseStudy_type_item Work_caseStudy_type_item__behance'>
                                        Graphic Design
                                    </span>
                                    <span className='Work_caseStudy_type_item Work_caseStudy_type_item__behance'>
                                        Web Design
                                    </span>
                                </div>
                                <p className='Work_caseStudy_text'>
                                    I enjoy designing various type of products in my spare time :)
                                </p>
                                <a className='Work_caseStudy_link Work_caseStudy_link__chataid' href="https://www.behance.net/ayakademura" target="_blank" rel="noreferrer">
                                    <span className='Work_caseStudy_link_text Work_caseStudy_link_text__behance'>View Work</span>
                                    <Icon className='Work_caseStudy_link_icon Work_caseStudy_link_icon__behance' icon="dashicons:arrow-right-alt" color="#4784fd" width="20"/>
                                </a>
                            </div>
                        </div>
                    </a>
                </div> */}
                
            </div>
            
        </section>
    );
}
  
export default Work;