function Maintenance() {
    return (
        <div className='Maintenance'>
            <h2 className='MaintenanceTitle'>
                Maintenance Mode
            </h2>

            <div className='MaintenanceText'>
                <p>
                    Will be back soon!
                </p>
                {/* <p>
                    You can check my work on <a href="https://www.behance.net/ayakademura" target="_blank" rel="noopener">here</a>
                </p>

                <div className='MaintenanceContact'>
                    Feel free to contact me :)<br></br> <a href="mailto:ayakade13@gmail.com">ayakade13@gmail.com</a>
                </div> */}
            </div>
        </div>
    );
}
  
export default Maintenance;