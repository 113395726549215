import React from 'react';
import ProgressBar from "react-progressbar-on-scroll";

import Header from "../../Components/header/header";
import VeganMenu from "../../Components/caseStudy/VeganMenu/VeganMenu";
import Contact from "../../Components/contact/contact";
import Footer from "../../Components/footer/footer";

const CaseStudyVeganMenu = () => {
  return (
    <div className="CaseStudy">
         <ProgressBar
          color="#91A88E"
          height={6}
        />
        <Header />
        <VeganMenu />
        <Contact />
        <Footer />
    </div>
  );
}

export default CaseStudyVeganMenu;