function ChatAidProblem() {
    return (
        <div className='CaseStudy_problem CaseStudy_section'>
            <h3 className='CaseStudy_item_title CaseStudy_item_title__chataid'>
                Problem
            </h3>
            <p className='CaseStudy_problem_text'>
                Teens are struggling with mental health and looking for a safe place to open up about their feeling and mental health issues at school
            </p>

            <div className='CaseStudy_problem_resource'>
                <p className='CaseStudy_problem_resource_title'>
                    7 in 10 teens are struggling with mental health
                </p>
                <p className='CaseStudy_problem_resource_text'>
                    In May 2020, <a className='CaseStudy_problem_resource_text_link' href="https://www.prnewswire.com/news-releases/new-survey-finds-7-in-10-teens-are-struggling-with-mental-health-301078336.html" target="_blank" rel="noreferrer">a survey</a> polled over 1,500 youth between the ages of 13-19 in the US.
                </p>

                <p className='CaseStudy_problem_resource_list_title'>
                    Key findings from the survey include:
                </p>
                <ul className='CaseStudy_problem_resource_list_wrapper'>
                    <li className='CaseStudy_problem_resource_list_item'>
                        – Teens are aware that mental health is a significant issue for young people
                    </li>
                    <li className='CaseStudy_problem_resource_list_item'>
                        – They feel more pressured to hide their feelings
                    </li>
                    <li className='CaseStudy_problem_resource_list_item'>
                        – They try to ignore their feelings or spend more time alone when dealing with mental health issues
                    </li>
                    <li className='CaseStudy_problem_resource_list_item'>
                        – Many teens use social media to learn about mental health
                    </li>
                    <li className='CaseStudy_problem_resource_list_item'>
                        – They wish there was a safe space for people in school to talk about mental health and school taught them more about mental health.
                    </li>
                </ul>

            </div>
        </div>
    );
}

export default ChatAidProblem;