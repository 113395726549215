import Outcome from "../../../imgs/caseStudy/ChatAid/chataid_outcome.jpg"

function ChatAidOutcome() {
    return (
        <div className='CaseStudy_outcome CaseStudy_section'>
            <h3 className='CaseStudy_item_title CaseStudy_item_title__outcome CaseStudy_item_title__chataid'>
                Outcome
            </h3>
            <img className='CaseStudy_outcome_design' src={Outcome} alt="Outcome Design"/>
        </div>
    );
}

export default ChatAidOutcome;