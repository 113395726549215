function ChatAidGoal() {
    return (
        <div className='CaseStudy_goal CaseStudy_section'>
            <h3 className='CaseStudy_item_title CaseStudy_item_title__chataid'>
                Goal
            </h3>
            <p className='CaseStudy_goal_text'>
                – Create a social media app for teens and schools to improve mental health
            </p>
            <p className='CaseStudy_goal_text'>
                – Give teens more opportunities to talk and learn about mental health
            </p>
        </div>
    );
}

export default ChatAidGoal;