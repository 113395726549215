import React, { Component } from 'react';
import Resume from '../../documents/AyakaDemuraResume.pdf';

// import Amsterdam from '../../imgs/about/amsterdam.jpg';
// import EDC from '../../imgs/about/edc.jpg';
// import Germany from '../../imgs/about/germany.jpg';
// import LosAngeles from '../../imgs/about/losangeles.png';
// import NewZealand from '../../imgs/about/newzealand.jpg';
// import Tasmania from '../../imgs/about/tasmania.jpg';
import MeImage from '../../imgs/about/about_me.jpg';

class AboutContent extends Component {
    render() {
        return (
            <div className='About_wrapper'>
                <div className='About_title_wrapper'>
                    <h3 className='About_title'>About Me</h3>
                </div>

                <div className='About_content_wrapper'>
                    <div className='About_content_row'>
                        <div className='About_content'>
                            <div className='About_me_image_wrapper sp_only'>
                                <img className='About_me_image' src={MeImage} alt=""/>
                            </div>

                            <div className='About_text_wrapper'>
                                <p className='About_text'>I am a <span className='About_text_bold'>Visual & UX Designer</span> from Japan with a <span className='About_text_bold'>passion for problem-solving through design</span>. With <span className='About_text_bold'>expertise in UI/UX design and development</span>, I graduated from the Web Development and Interactive Design program at the Vancouver Institute of Media Arts (VanArts) in Vancouver, Canada.</p>
                                <p className='About_text'> When I'm researching the latest trends or using my creativity to come up with fresh solutions, I always strive to create innovative and engaging designs.</p>
                                <p className='About_text'>As a <span className='About_text_bold'>lifelong learner</span>, my growth mindset always keeps me eager to learn new technologies and skills, and I <span className='About_text_bold'>enjoy challenging myself with new opportunities</span>. When I'm working on a new project or collaborating with a team, I am <span className='About_text_bold'>committed to delivering high-quality work that exceeds expectations</span>.</p>
                            </div>

                            <div className='About_link_wrapper'>
                                <a className='About_link' href="https://www.linkedin.com/in/ayakademura/" target="_blank" rel="noreferrer">LinkedIn</a>
                                <a className='About_link' href={Resume} target="_blank" rel="noreferrer">Resume</a>
                            </div>

                            <div className='About_interest_wrapper'>
                                <span className='About_interest_title'>
                                    I'm interested in
                                </span>
                                <p className='About_interest_text'>
                                    🎧 Music / ✈️ Travel / 🎨 Art / 🐱 Cats / 💙 Mental health /<br className='pc_only'></br> 🍀 Wellness / 🧠 Psychology
                                </p>
                            </div>
                        </div>
                    
                        <div className='About_content'>
                            <div className='About_me_image_wrapper pc_only'>
                                <img className='About_me_image' src={MeImage} alt=""/>
                            </div>
                        </div>
                    </div>

                    <div className='About_skills'>
                        <h4 className='About_skills_title'>Skills</h4>
                        <div className='About_skills_list'>
                            <div className='About_skills_item'>
                                <h5 className='About_skills_item_title'>
                                    <span className='About_skills_item_title_icon'>
                                        🎨
                                    </span>
                                    Design
                                </h5>
                                <p className='About_skills_item_content'
                                    >UI/UX Design / Graphic Design / <br className='pc_only'></br>Web Design / Wireframes / <br className='pc_only'></br>User Research / Prototyping / <br className='pc_only'></br> Creative Thinking
                                </p>
                            </div>
                            <div className='About_skills_item'>
                                <h5 className='About_skills_item_title'>
                                    <span className='About_skills_item_title_icon'>
                                        💼
                                    </span>
                                    Tools
                                </h5>
                                <p className='About_skills_item_content'>
                                    Figma / Adobe Photoshop / <br className='pc_only'></br>Adobe Illustrator / Adobe XD
                                </p>
                            </div>
                            <div className='About_skills_item'>
                                <h5 className='About_skills_item_title'>
                                    <span className='About_skills_item_title_icon'>
                                        💻
                                    </span>
                                    Programming
                                </h5>
                                <p className='About_skills_item_content'>
                                    HTML / CSS / Sass / Javascript / jQuery / React / PHP / Wordpress / Shopify / Github
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* <div className='About_travel'>
                        <p className='About_travel_title'>When I'm not with my laptop, <br className='sp_only'></br>I enjoy <span className='blue'>traveling the world</span> ✈️</p>
                        <div className='About_travel_list'>
                            <div className='About_travel_list_item'>
                                <img className='About_travel_list_img' src={Amsterdam} alt="Amsterdam"/>
                                <span className='About_travel_list_location'>
                                    <span className='About_travel_list_location_text'>
                                        🇳🇱 Amsterdam
                                    </span>
                                </span>
                            </div>
                            <div className='About_travel_list_item'>
                                <img className='About_travel_list_img' src={Germany} alt="Düsseldorf"/>
                                <span className='About_travel_list_location'>
                                    <span className='About_travel_list_location_text'>
                                        🇩🇪 Düsseldorf
                                    </span>
                                </span>
                            </div>
                            <div className='About_travel_list_item'>
                                <img className='About_travel_list_img' src={EDC} alt="Las Vegas"/>
                                <span className='About_travel_list_location'>
                                    <span className='About_travel_list_location_text'>
                                    🇺🇲 Las Vegas
                                    </span>
                                </span>
                            </div>
                            <div className='About_travel_list_item'>
                                <img className='About_travel_list_img' src={LosAngeles} alt="Los Angeles"/>
                                <span className='About_travel_list_location'>
                                    <span className='About_travel_list_location_text'>
                                        🇺🇲 Los Angeles
                                    </span>
                                </span>
                            </div>
                            <div className='About_travel_list_item'>
                                <img className='About_travel_list_img' src={NewZealand} alt="Auckland"/>
                                <span className='About_travel_list_location'>
                                    <span className='About_travel_list_location_text'>
                                        🇳🇿 Auckland
                                    </span>
                                </span>
                            </div>
                            <div className='About_travel_list_item'>
                                <img className='About_travel_list_img' src={Tasmania} alt="Tasmania"/>
                                <span className='About_travel_list_location'>
                                    <span className='About_travel_list_location_text'>
                                        🇦🇺 Tasmania
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}
  
export default AboutContent;