import MonaCoMonaCoFinalDesignImage from "../../../imgs/caseStudy/MonaCo/MonaCo_finalDesign.jpg"

function MonaCoFinalDesign() {
    return (
        <div className='CaseStudy_finalDesign_wrapper CaseStudy_finalDesign_wrapper__monaCo CaseStudy_section__maxWidth'>
            <div className='CaseStudy_section_content_item'>
                <img className='CaseStudy_section_content_item_img CaseStudy_section_content_item_img__finalDesign' src={MonaCoMonaCoFinalDesignImage} alt="Final Design"/>
            </div>
        </div>
    );
}

export default MonaCoFinalDesign;