import MonaCoMoodStyleGuide from "../../../imgs/caseStudy/MonaCo/MonaCo_styleGuide.jpg"

function MonaCoStyleGuide() {
    return (
        <div className='CaseStudy_styleGuide_wrapper CaseStudy_section'>
            <div className='CaseStudy_section_row CaseStudy_section_row__styleGuide'>
                <div className='CaseStudy_section_title CaseStudy_section_title__styleGuide'>
                    <span className='CaseStudy_section_title_sub'>
                        Design
                    </span>
                    <h3 className='CaseStudy_section_title_title'>
                        Style Guide
                    </h3>
                </div>

                <div className='CaseStudy_section_content'>
                    <div className='CaseStudy_section_content_item'>
                        <p className='CaseStudy_section_content_item_text'>
                            I created a style guide that incorporates beach vibes and handmade texture.
                        </p>
                    </div>
                </div>
            </div>

            <div className='CaseStudy_section_content_item'>
                <img className='CaseStudy_section_content_item_img CaseStudy_section_content_item_img__styleGuide' src={MonaCoMoodStyleGuide} alt="Style Guide"/>
            </div>
        </div>
    );
}

export default MonaCoStyleGuide;