function ChatAidDetail() {
    return (
        <div className='CaseStudy_detail CaseStudy_section'>
            <h3 className='CaseStudy_item_title CaseStudy_item_title__chataid'>
                About
            </h3>

            <div className='CaseStudy_detail_wrapper'>
                <div className='CaseStudy_detail_row CaseStudy_detail_row--about'>
                    <div className='CaseStudy_detail_about'>
                        <p className='CaseStudy_detail_about_text'>
                            This is my personal business idea for a school project. Mental health is one of my biggest interests. Because of my past experience, I have always wanted to help other people struggling with mental health.
                        </p>
                        <p className='CaseStudy_detail_about_text'>
                            According to <a className='CaseStudy_detail_about_link' href="https://www.prnewswire.com/news-releases/new-survey-finds-7-in-10-teens-are-struggling-with-mental-health-301078336.html" target="_blank" rel="noreferrer">the article</a>, <span className='bold'>7 in 10 teens are struggling with mental health</span>. I found that social media is the main resource for many teens to learn about mental health. Also, they wish there was a safe place to talk and learn about mental health at school. Based on that, I came up with the idea of a <span className='bold'>social media app for teens and schools to improve their mental health</span>.
                        </p>
                    </div>
                </div>

                <div className='CaseStudy_detail_row CaseStudy_detail_row--detail'>
                    <div className='CaseStudy_detail_item_wrapper'>
                        <div className='CaseStudy_detail_item'>
                            <h4 className='CaseStudy_detail_item_title CaseStudy_detail_item_title__chataid'>
                                Role
                            </h4>
                            <p className='CaseStudy_detail_item_text'>
                                UI/UX Designer
                            </p>
                        </div>

                        <div className='CaseStudy_detail_item'>
                            <h4 className='CaseStudy_detail_item_title CaseStudy_detail_item_title__chataid'>
                                Timeline
                            </h4>
                            <p className='CaseStudy_detail_item_text'>
                                Jan 2022 – Feb 2022 (4 weeks)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChatAidDetail;