import React from 'react';

import Header from '../header/header';
import DailyUIContent from './dailyUI_content';
import Contact from "../contact/contact";
import Footer from "../footer/footer";

function DailyUI() {
    return (
        <div className="DailyUI">
            <Header />
            <DailyUIContent/>
            <Contact />
            <Footer/>
        </div>
    );
}

export default DailyUI;