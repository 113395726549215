import React from 'react';

import MonaCoAbout from "./about";
import MonaCoContext from "./context";
import MonaCoProcess from './process';
import MonaCoMoodBoard from './moodboard';
import MonaCoWireframe from './wireframe';
import MonaCoStyleGuide from './styleguide';
import MonaCoFinalDesign from './finalDesign';
import MonaCoTakeaways from './takeaways';
// import ChatAidProblem from "./problem";
// import ChatAidGoal from "./goal";
// import ChatAidReserach from "./research";
// import ChatAidPersonas from "./personas";
// import ChatAidSitemapUserflow from "./sitemap_userflow";
// import ChatAidUserTestJourney from "./usertest_journey";
// import ChatAidOutcome from "./outcome";
// import ChatAidTakeaways from "./takeaways";

import MonaCoHero from "../../../imgs/caseStudy/MonaCo/MonaCo_hero.jpg"

function MonaCo() {
    return (
        <div className='CaseStudy_wrapper'>
            <div className='CaseStudy_hero CaseStudy_hero__MonaCo'>
                <img className='CaseStudy_hero_img' src={MonaCoHero} alt="Mona & Co"/>
            </div>

            <div className='CaseStudy_container'>
                <MonaCoAbout />
                <MonaCoContext />
                <MonaCoProcess />
                <MonaCoMoodBoard />
                <MonaCoWireframe />
                <MonaCoStyleGuide />
                <MonaCoFinalDesign />
                <MonaCoTakeaways />
            </div>
        </div>
    );
}
    
export default MonaCo;