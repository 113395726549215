function MonaCoTakeaways() {
    return (
        <div className='CaseStudy_context_wrapper CaseStudy_section'>
            <div className='CaseStudy_section_title'>
                <h3 className='CaseStudy_section_title_title'>
                    Takeaways
                </h3>
            </div>

            <div className='CaseStudy_section_content'>
                <div className='CaseStudy_section_content_item'>
                    <h4 className='CaseStudy_section_content_item_title'>
                        My first experience with Shopify
                    </h4>
                    <p className='CaseStudy_section_content_item_text'>
                        This project was my first experience with Shopify/CMS. Although I know how to code and build websites, I had no prior knowledge of Shopify. Additionally, I did not know anyone who was familiar with the platform, so I had to figure everything out by myself.
                    </p>
                    <p className='CaseStudy_section_content_item_text'>
                        At the beginning, it was very challenging. I had to watch many tutorial videos and search on Google for coding information. Due to my limited knowledge of Shopify, I had to design based on what I could actually code while still making it visually appealing.
                    </p>
                    <p className='CaseStudy_section_content_item_text'>
                        Despite these difficulties, I believe that I did my best with the design and gained confidence in the end.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default MonaCoTakeaways;