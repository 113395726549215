function AlixKerryDetail() {
    return (
        <div className='CaseStudy_detail CaseStudy_section'>
            <h3 className='CaseStudy_item_title CaseStudy_item_title__alixkerry'>
                About
            </h3>

            <div className='CaseStudy_detail_wrapper'>
                <div className='CaseStudy_detail_row CaseStudy_detail_row--about'>
                    <div className='CaseStudy_detail_about'>
                        <p className='CaseStudy_detail_about_text'>
                            This is my personal business idea for a school project. Mental health is one of my biggest interests. Because of my past experience, I have always wanted to help other people struggling with mental health.
                        </p>
                    </div>
                </div>

                <div className='CaseStudy_detail_row CaseStudy_detail_row--detail'>
                    <div className='CaseStudy_detail_item_wrapper'>
                        <div className='CaseStudy_detail_item'>
                            <h4 className='CaseStudy_detail_item_title CaseStudy_detail_item_title__alixkerry'>
                                Role
                            </h4>
                            <p className='CaseStudy_detail_item_text'>
                                Web Designer & Front-End Developer
                            </p>
                        </div>

                        <div className='CaseStudy_detail_item'>
                            <h4 className='CaseStudy_detail_item_title CaseStudy_detail_item_title__alixkerry'>
                                Timeline
                            </h4>
                            <p className='CaseStudy_detail_item_text'>
                                Apr 2020 - June 2020 (8 weeks)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AlixKerryDetail;