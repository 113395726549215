import SurveyQuestions from "../../../imgs/caseStudy/ChatAid/chataid_survey_questions.jpg"

function ChatAidSurvey() {
    return (
        <div className='CaseStudy_reserach_survey'>
            <h4 className='CaseStudy_reserach_survey_title'>
                Survey <span className='light'>(Quantitative research)</span>
            </h4>
            <p className='CaseStudy_reserach_survey_text'>
                With some assumption in mind, I conducted a survey to learn and discover the pain points regards mental health with unspecific demographic group. The questions I asked were:
            </p>
            <img className='CaseStudy_reserach_survey_question' src={SurveyQuestions} alt="Survey Questions"/>
            
            <div className='CaseStudy_reserach_survey_result'>
                <h5 className='CaseStudy_reserach_survey_result_title'>
                    What I learned & found:
                </h5>
                <ol className='CaseStudy_reserach_survey_result_list'>
                    <li className='CaseStudy_reserach_survey_result_list_item'>
                        1. Many people have difficulty sharing their feelings and struggling alone. 
                    </li>
                    <li className='CaseStudy_reserach_survey_result_list_item'>
                        2. They feel that sharing their feeling is hard because they do not know how to explain it.
                    </li>
                    <li className='CaseStudy_reserach_survey_result_list_item'>
                        3. Most of them do not journal or write down their thoughts or feelings at all.
                    </li>
                </ol>
            </div>

            <a className='CaseStudy_reserach_survey_link' href="https://drive.google.com/file/d/1E2qeKNj_Bf61Cv_lt9LdvuWPEi3w6-8Q/view?usp=sharing" target="_blank" rel="noreferrer">
                Survey questions & results
            </a>
        </div>
    );
}

export default ChatAidSurvey;