import ChatAidSurvey from "./survey";
import ChatAidInterview from "./interview";

function ChatAidReserach() {
    return (
        <div className='CaseStudy_reserach CaseStudy_section'>
            <h3 className='CaseStudy_item_title CaseStudy_item_title__chataid'>
                Research
            </h3>
            <ChatAidSurvey />
            <ChatAidInterview />
        </div>
    );
}

export default ChatAidReserach;