import MonaCoWireframeImage from "../../../imgs/caseStudy/MonaCo/MonaCo_wireframe.jpg"

function MonaCoWireframe() {
    return (
        <div className='CaseStudy_wireframe_wrapper CaseStudy_section__maxWidth'>
            <div className='CaseStudy_section_content_item'>
                <img className='CaseStudy_section_content_item_img CaseStudy_section_content_item_img__wireframe' src={MonaCoWireframeImage} alt="Wireframe"/>
            </div>
        </div>
    );
}

export default MonaCoWireframe;