function MonaCoProcess() {
    return (
        <div className='CaseStudy_context_wrapper CaseStudy_section'>
            <div className='CaseStudy_section_title'>
                <h3 className='CaseStudy_section_title_title'>
                    Design Process
                </h3>
            </div>

            <div className='CaseStudy_section_content'>
                <div className='CaseStudy_section_content_item'>
                    <h4 className='CaseStudy_section_content_item_title'>
                        Competitors Research
                    </h4>
                    <p className='CaseStudy_section_content_item_text'>
                        I began the design process with competitor research. After reviewing the websites they referred me to, I noticed that many of them have "about" and "contact" pages. 
                    </p>
                    <p className='CaseStudy_section_content_item_text'>
                        <span className='bold'>
                            Based on the research, I decided to add "about" and "contact" pages since these pages are essential for telling the brand's story and facilitating communication with customers. 
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default MonaCoProcess;