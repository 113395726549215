// import { Icon } from '@iconify/react';

import LinkedIn from '../../imgs/ico/ico_linkedin.svg';
import Behance from '../../imgs/ico/ico_behance.svg';
import Instagram from '../../imgs/ico/ico_instagram.svg';

function Contact() {
    return (
        <div className='Contact' id='Contact'>
            <h3 className='Contact_title'>Let's get in touch!</h3>
            <a className='Contact_email' href="mailto:ayakde13@gmail.com">
                {/* <img className='Contact_email_ico' src={Email} alt="Email" /> */}
                <span className='Contact_email_emoji'>
                    💌
                </span>
                <span className='Contact_email_email'>
                    ayakade13@gmail.com
                </span>
            </a>
            <div className='Social_media'>
                <a className='Social_media_link' href="https://www.linkedin.com/in/ayakademura/" target="_blank" rel="noreferrer">
                    <img className='Social_media_link_ico' src={LinkedIn} alt="LinkedIn" />
                    {/* <Icon className='Social_media_link_ico' icon="akar-icons:linkedin-fill" width="24" color="42518D" /> */}
                </a>
                <a className='Social_media_link' href="https://www.behance.net/ayakademura" target="_blank" rel="noreferrer">
                    <img  className='Social_media_link_ico' src={Behance} alt="Behance" />
                    {/* <Icon className='Social_media_link_ico' icon="ri:behance-fill" width="40"/> */}
                </a>
                <a className='Social_media_link' href="https://www.instagram.com/ayaka_demura/" target="_blank" rel="noreferrer">
                    <img  className='Social_media_link_ico' src={Instagram} alt="Instagram" />
                    {/* <Icon className='Social_media_link_ico' icon="ant-design:instagram-outlined" width="60"/> */}
                </a>
            </div>
        </div>
    );
}
  
export default Contact;