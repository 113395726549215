function ChatAidUserTestJourney() {
    return (
        <div className='CaseStudy_usertest CaseStudy_section'>
            <h3 className='CaseStudy_item_title CaseStudy_item_title__chataid'>
                User testing
            </h3>
            <div className='CaseStudy_usertest_text'>
                <p className='CaseStudy_usertest_text_item'>
                    After creating wireframe and mockups, I made first prototype with Figma. Using the prototype, I gave users a few tasks to use the application and check how the process and experience are.
                </p>
                <p className='CaseStudy_usertest_text_item'>
                    From the result, I found: 
                    <ul className='CaseStudy_usertest_result_list'>
                        <li className='CaseStudy_usertest_result_list_item'>
                            - some people felt it was hard to find a journal page from home
                        </li>
                        <li className='CaseStudy_usertest_result_list_item'>
                            - some of them wanted to read past journals first but could not find them easily
                        </li>
                    </ul>
                </p>
                <p className='CaseStudy_usertest_text_item'>
                    These are not great experiences since journaling is one of the most important functions of the application. I definitely need to fix it.
                </p>
            </div>
            
        </div>
    );
}

export default ChatAidUserTestJourney;