import MonaCoMoodBoardImage from "../../../imgs/caseStudy/MonaCo/MonaCo_moodBoard.jpg"

function MonaCoMoodBoard() {
    return (
        <div className='CaseStudy_moodBoard_wrapper CaseStudy_section'>
            <div className='CaseStudy_section_row'>
                <div className='CaseStudy_section_title CaseStudy_section_title__moodBoard'>
                    <span className='CaseStudy_section_title_sub'>
                        Design
                    </span>
                    <h3 className='CaseStudy_section_title_title'>
                        Mood Board
                    </h3>
                </div>

                <div className='CaseStudy_section_content'>
                    <div className='CaseStudy_section_content_item'>
                        <p className='CaseStudy_section_content_item_text'>
                            I created a mood board on Pinterest to brainstorm and organize my ideas for page layout and visual design. 
                        </p>
                        <p className='CaseStudy_section_content_item_text'>
                            I found some inspiration related to <span className='bold'>beach, natural, and handmade textures</span>, are in line with their brand.
                        </p>
                    </div>
                </div>
            </div>

            <div className='CaseStudy_section_content_item'>
                <img className='CaseStudy_section_content_item_img CaseStudy_section_content_item_img__moodboard' src={MonaCoMoodBoardImage} alt="MoodBoard"/>
            </div>
        </div>
    );
}

export default MonaCoMoodBoard;