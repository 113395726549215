import Sitemap from "../../../imgs/caseStudy/ChatAid/chataid_sitemap.jpg";
import Userflow from "../../../imgs/caseStudy/ChatAid/chataid_userflow.jpg"

function ChatAidSitemapUserflow() {
    return (
        <div className='CaseStudy_sitemap_userflow CaseStudy_section'>
            <h3 className='CaseStudy_item_title CaseStudy_item_title__chataid'>
                Sitemap & Userflow
            </h3>
            <p className='CaseStudy_sitemap_userflow_text'>
                After the research and brainstorming, I decided to add chat/message and journal are the main functions of the application. Users also can make an appointment with school counselors. Students are the main user. Teachers, school counselors, and parents also use the application to communicate with each other. 
            </p>

            <div className='CaseStudy_sitemap'>
                <h4 className='CaseStudy_sitemap_title'>
                    Sitemap
                </h4>

                <div className='CaseStudy_sitemap_content'>
                    <div className='CaseStudy_sitemap_content_row'>
                        <img className='CaseStudy_sitemap_sitemap' src={Sitemap} alt="Sitemap"/>
                    </div>

                    <div className='CaseStudy_sitemap_content_row'>
                        <div className='CaseStudy_sitemap_item'>
                            <span className='CaseStudy_sitemap_item_title'>
                                Journal
                            </span>
                            <ul className='CaseStudy_sitemap_item_list'>
                                <li className='CaseStudy_sitemap_item_list_item'>
                                    - Journal just for themselves or share with other users like Facebook
                                </li>
                                <li className='CaseStudy_sitemap_item_list_item'>
                                    - Read users' journals in the past
                                </li>
                            </ul>
                        </div>

                        <div className='CaseStudy_sitemap_item'>
                            <span className='CaseStudy_sitemap_item_title'>
                                Chat
                            </span>
                            <ul className='CaseStudy_sitemap_item_list'>
                                <li className='CaseStudy_sitemap_item_list_item'>
                                    - Chat with other users
                                </li>
                                <li className='CaseStudy_sitemap_item_list_item'>
                                    - Chat with school counselors and make an appointment with them
                                </li>
                            </ul>
                        </div>

                        <div className='CaseStudy_sitemap_item'>
                            <span className='CaseStudy_sitemap_item_title'>
                                User
                            </span>
                            <ul className='CaseStudy_sitemap_item_list'>
                                <li className='CaseStudy_sitemap_item_list_item'>
                                    - Edit user information
                                </li>
                                <li className='CaseStudy_sitemap_item_list_item'>
                                    - Check & make an appointment with school counselors
                                </li>
                                <li className='CaseStudy_sitemap_item_list_item'>
                                    - General settings 
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className='CaseStudy_userflow'>
                <h4 className='CaseStudy_userflow_title'>
                    Userflow
                </h4>
                <img className='CaseStudy_userflow_userflow' src={Userflow} alt="SUserflow"/>
            </div>
        </div>
    );
}

export default ChatAidSitemapUserflow;