// import { Parallax } from 'react-scroll-parallax';

import Header from "../../Components/header/header";
import Hero from "../../Components/hero/hero";
import Work from "../../Components/work/work";
// import About from "../../Components/about/about";
import Contact from "../../Components/contact/contact";
import Footer from "../../Components/footer/footer";
// import FadeIn from 'react-fade-in';

// Maintenance mode
import Maintenance from "../../Components/maintenance/maintenance";

function Home() {
  return (
    <div className="Home">
        <Maintenance />
    </div>
  );
}

export default Home;