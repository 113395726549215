import React from 'react';
import ProgressBar from "react-progressbar-on-scroll";

import Header from "../../Components/header/header";
import AlixKerry from '../../Components/caseStudy/alixkerry/alixkerry';
import Contact from "../../Components/contact/contact";
import Footer from "../../Components/footer/footer";

const CaseStudyAlixKerry = () => {
  return (
    <div className="CaseStudy">
         <ProgressBar
          color="#C8A3B5"
          // gradient={true}
          // colorGradient="#77b4e2"
          height={6}
        />
        <Header />
        <AlixKerry />
        <Contact />
        <Footer />
    </div>
  );
}

export default CaseStudyAlixKerry;